import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`備忘録中心ですが、技術よりの雑記ブログです。`}<br parentName="p"></br>{`
`}{`初心者なので、初心者向けです。  `}</p>
    <p>{`主な環境は、`}</p>
    <ul>
      <li parentName="ul">{`MacBook Air: macOS Big Sur`}</li>
      <li parentName="ul">{`Raspberry Pi: Raspberry Pi OS Bullseye`}</li>
      <li parentName="ul">{`ThinkPad X230: NetBSD/amd64 9.2`}</li>
    </ul>
    <p>{`です。   `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      